@use "sass:math";

@import "font";
@import "bootstrap";
@import "customStylesSwiper";
@import "~flatpickr/dist/themes/airbnb.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "smartbanner";
@import "social-shares.scss";

body {
  background-color: #f7f7f7;
  font-weight: 300;
  letter-spacing: .02rem;
  font-size: 0.95rem;

  @include media-breakpoint-down(md) {
    background: #FFFFFF;
    font-size: 0.85rem;
    overflow-x: hidden;
  }

  &.search-overlay {
    overflow: hidden;
  }
}

.bg-white {
  background: $white;

  @include media-breakpoint-down(md) {
    background: #f6f6f6;
  }
}

.hidden {
  display: none;
}

.overflowHidden {
  overflow: hidden;
}

.md-hide {
  display: block;

  @include media-breakpoint-down(md) {
    display: none !important;
  }
}

.md-show {
  display: none;

  @include media-breakpoint-down(md) {
    display: block !important;
  }
}

.flatpickr-input {
  height: 50px;
  font-size: math.div($size-base * 15, 18);
  line-height: 50px;
  // background: url('../public/assets/calendar.svg') 90% 50% no-repeat;
  padding: 0 50px 0 20px;
  border: 1px solid rgba(12, 113, 176, 0.2);
  border-radius: 4px;
  -webkit-appearance: none;
  min-width: 95%;
  background: inherit;
}


svg {
  height: $size-base * 2;
  fill: $primary;
}

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }

  @-moz-keyframes #{$animation-name} {
    @content;
  }

  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  animation: #{$str};
}

@include keyframes(AnimationName) {
  0% {
    background-position: 0 50%;
  }

  100% {
    background-position: 100% 50%;
  }
}

.blur-content {
  filter: opacity(0.7) grayscale(0.5) blur(1px);
  pointer-events: none;

  figure,
  &.animated-blur {
    background-image: linear-gradient(325deg, #ffffff, #ffffff, #8cc0f3, #ffffff, #ffffff) !important;
    background-size: 800% 800% !important;

    @include animation('AnimationName 3s ease infinite');
  }
}

.content-container {
  padding: 1.5rem 0 0 0;

  @include media-breakpoint-down(xl) {
    padding: 0;
  }
}

.layout-container {
  background-color: #fff;
  padding-bottom: 120px;

  @include media-breakpoint-up(lg) {
    padding: 1rem;
    padding-bottom: 120px;
  }

  @include media-breakpoint-down(md) {
    margin-top: -1rem;
    padding: 10px 0 0 0;
  }
}

.layout-container-noback {
  padding-bottom: 120px;

  @include media-breakpoint-up(lg) {
    padding: 1rem;
    padding-bottom: 120px;
  }

  @include media-breakpoint-down(md) {
    padding: 10px 0 0 0;
  }
}

.form {
  margin-top: 24px;

  @include media-breakpoint-down(md) {
    padding: 0 10px;
    margin-top: 0;
  }

  .row {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    label {
      font-size: 14 * $px-base;
      line-height: 20 * $px-base;
      color: $black;
      text-transform: uppercase;
      margin-bottom: 7px;

      &.label-invalid {
        color: $statusClosed;
      }
    }

    .pros {
      padding-right: 13px;

      @include media-breakpoint-down(md) {
        margin-bottom: 20px;
      }
    }

    .cons {
      padding-left: 13px;
    }
  }

  .input,
  .textarea {
    width: 100%;
    padding: 15px 18px;
    font-size: 16 * $px-base;
    line-height: 20 * $px-base;
    color: $secondary-blue;
    background: white;
    border: 1px solid rgba(12, 113, 176, 0.2);
    box-sizing: border-box;
    border-radius: 5px;
    outline: none;

    &::placeholder {
      opacity: 0.4;

      @include media-breakpoint-down(md) {
        font-size: 14 * $px-base;
      }
    }

    &:focus {
      border: 1px solid rgba(12, 113, 176, 0.8);
    }
  }

  .textarea {
    height: 94px;

    @include media-breakpoint-down(md) {
      height: 152px;
      font-size: 14 * $px-base;
    }
  }

  .required {
    color: $statusClosed;
    margin-left: 3px;
  }

  .invalid {
    border: 1px solid $statusClosed;
  }

  .cons {
    color: $statusClosed;
  }
}

a#ot-sdk-btn.ot-sdk-show-settings,
a#ot-sdk-btn.optanon-show-settings {
  font-size: math.div($size-base * 12, 16);
  padding: 0;
  border: none;
  background: inherit;
  color: #6F6F6F;

  &:hover,
  &:active {
    text-decoration: underline;
    border: none;
    background: inherit;
    color: #6F6F6F;
  }
}

.hero {
  &.gradient {
    z-index: 1;
    position: absolute;
    bottom: 0;
    top: 0;
    width: 100%;
    background: linear-gradient(0deg, rgba(0, 111, 172, 0.1), rgba(0, 111, 172, 0.1));
  }

  &.mountains {
    position: absolute;
    width: 100%;
    background: url(../public/assets/resort/white-mountains.svg) 50% 101% repeat-x;
    height: 75px;
    bottom: -2px;
    z-index: 4;

    @include media-breakpoint-down(lg) {
      display: none;
    }
  }
}

.w-100 {
  width: 100%;
}

div.lightbox-portal div.lightbox-container {
  z-index: 1001;
}